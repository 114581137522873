import React from 'react';

import './SubFooter.scss';
import { MailIcon } from '../../Atoms/Icons/Icons';

function SubFooter({ title, link, linkText }) {
  return (
    <div className='SubFooter-wrapper'>
      <div className="contacct-wrapper">
        <h1> {title}</h1>
      </div>
      <div className="mail_wrapper">
        <a href={link}>
          <MailIcon />
          <p dangerouslySetInnerHTML={{ __html: linkText }} />
        </a>

      </div>
    </div>
  );
}

export default SubFooter;
