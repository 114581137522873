import React, { useEffect } from 'react';

import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import { CloseIcon } from '../../../Atoms/Icons/Icons';
import Img_Temps_Abaisee from '../../../assets/images/popin-carbon-icons/img_temps_abaisee.png'
import Img_Mat_Recyclee from '../../../assets/images/popin-carbon-icons/img_mat_recyclee.png'
import Img_Mat_Perform from '../../../assets/images/popin-carbon-icons/img_mat_perform.png'
import Img_Mat_Carbon from '../../../assets/images/popin-carbon-icons/img_mat_carbon.png'
import Img_Mat_Biosource from '../../../assets/images/popin-carbon-icons/img_mat_biosource.png'

import './PopinLowCarbon.scss';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';

const PopinLowCarbon = ({ openProps, onCloseClick }) => {
  const intl = useIntl();
  const onHideAccessibilite = (e) => {
    e.preventDefault();
    onCloseClick(false);
  };
  useEffect(()=>{
    openProps? document.body.classList.add('show_popin_carbon') : document.body.classList.remove('show_popin_carbon')
  }, [openProps])

  return (
    <div className="popin_carbon" role="dialog" aria-labelledby="accessmodaltitle">
      <div className="popin_content">
        <div className="content_wrapper">
          <TitleSection 
            title={intl.formatMessage({ id: 'carbon.title' })} 
            type="arrow"
            h3color='dark_bleu'
          />
          <div className="popin_carbon_content">
            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'carbon.subtitle' }) }} />
            <ul>
              <li>
                  <div className="img_wrap">
                    <img src={Img_Temps_Abaisee} alt=""/>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'carbon.list1' }) }} />
              </li>
              <li>
                  <div className="img_wrap">
                    <img src={Img_Mat_Recyclee} alt=""/>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'carbon.list2' }) }} />
              </li>
              <li>
                  <div className="img_wrap">
                    <img src={Img_Mat_Perform} alt=""/>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'carbon.list3' }) }} />
              </li>
              <li>
                  <div className="img_wrap">
                    <img src={Img_Mat_Carbon} alt=""/>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'carbon.list4' }) }} />
              </li>
              <li>
                  <div className="img_wrap">
                    <img src={Img_Mat_Biosource} alt=""/>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'carbon.list5' }) }} />
              </li>
            </ul>
             <p className="legal" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'carbon.legal' }) }} />
          </div>
        </div>
        <div className="div_btn_close">
          <a href="#" aria-label={intl.formatMessage({ id: 'ariaLabel.close_modal' })} className="btn_close" onClick={(e) => onHideAccessibilite(e)}>
            <CloseIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PopinLowCarbon;
