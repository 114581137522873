import React, { useEffect, useState } from 'react';

import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';

import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import CardSection from '../../components/ContentType/Section/CardSection/CardSection';
import ProjectCards from '../../components/ProjectSquareComponent/ProjectSquareComponent';
import TextVisuelTwoCol from '../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import NosEngagement from '../../components/NosEngagement/NosEngagement';
import PageBanner from '../../components/PageBanner/PageBanner';
import Seo from '../../components/Seo/Seo';
import Slider from '../../components/Slider/Slider';
import SliderBullet from '../../components/SliderBullet/SliderBullet';
import SubFooter from '../../components/SubFooter/SubFooter';
import useMedias from '../../hooks/useMedias';
import Layout from '../../Layout';
import BasCarbonCheckBox from '../../Molecules/BasCarbonCheckBox/BasCarbonCheckBox';
import { Button } from '../../Molecules/Button/Button';
import FilterTag from '../../Molecules/FiltersTag/FiltersTag';
import ShapeBackground from '../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../Molecules/TitlePage/TitlePage';
import PopinVideo from '../../components/PopinVideo/PopinVideo';
import {
  getBreadCrumb,
  youtube_parser
} from '../../Utils/Utils';

import './styles.scss';
import PopinLowCarbon from '../../components/PopIn/PopinLowCarbon/PopinLowCarbon';
let classNames = require('classnames');

const DefaultPage = ({ data }) => {
  const intl = useIntl();
  const { getImage } = useMedias();

  const imagesArray = data?.allImages?.edges;
  const pageData = data?.pageData?.edges[0]?.node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, pageData?.path?.alias);
  const location = useLocation();

  const [InnerFilter, setInnerFilter] = useState([]);
  const [filter_tag, setFilterTag] = useState();
  const [changed, setChanged] = useState('');
  const [filterCheckBox, setFilterCheckBox] = useState(false);
  const [videoId, setVideoId] = useState('');
  const [stopVideo, setStopVideo] = useState(false);
  const [openPopinCarbon, setOpenPopinCarbon] = useState(false);
  const metaTags = pageData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });
  let filterArr = [];

  useEffect(() => {
    setInnerFilter(filterArr);
  }, []);

  useEffect(() => {
    let hashId = parseInt(location.hash.split('#')[1])
    if (hashId === 32) {
      checkVal(true);
    }
    else {
      onFilterChange(filterArr.filter(x => x.id === hashId)[0]);
    }

    if (location.hash != '') {
      setTimeout(scrollToTop, 500);
    }

  }, [location]);

  const checkVal = (val) => {
    setFilterCheckBox(val);
    setChanged(val);
    let _paq = window._paq = window._paq || [];
    _paq.push(['trackEvent', 'bas_carbone', 'bas carbone click', 'value', val]);

  };

  const scrollToTop = () => {
    let element = document.querySelector('.wrapperTop');
    let topPos = element.getBoundingClientRect().top + ((typeof window !== 'undefined') && window.pageYOffset) - 100;
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: topPos, // scroll so that the element is at the top of the view
        behavior: 'smooth' // smooth scroll
      });
    }
  };

  const onFilterChange = (tag) => {
    setFilterTag(tag);
    let section = document.querySelector('.section_' + tag?.id);
    let allSection = document.querySelectorAll('.product_page_component');
    allSection.forEach((el) => {
      tag ? el?.classList?.add('hide') : el?.classList?.remove('hide');
    });
    tag && section?.classList?.remove('hide');
    setChanged(tag)
  };

  const loadVideo = (val) => {
    setVideoId(youtube_parser(val));
    setStopVideo(false);
    document.body.classList.add('show_video_popin');
  };

  const closePopinVideo = () => {
    document.body.classList.remove('show_video_popin');
    setStopVideo(true);
    if (document.querySelector('a.accessibilite')) {
      document.querySelector('a.accessibilite').focus();
    }
  };

  return (
    <>
      <PopinVideo videoId={videoId} closePopinVideo={closePopinVideo} stopVideo={stopVideo} />
      <Layout imagesArray={imagesArray}>
        <Seo
          title={pageData?.field_metatag?.title ? pageData?.field_metatag?.title : metaTitle}
          description={pageData?.field_metatag?.description ? pageData?.field_metatag?.description : metaDesc}
        //shareImage={getImage(imagesArray, pageData.relationships.field_blocs[0]?.field_image?.drupal_internal__mid) || null}
        />
        <>
          <div
            className={classNames(
              'page_template',
              'page_default'
            )}
          >

            {
              pageData?.relationships?.field_blocs?.map((block, i) => {
                switch (block.__typename) {
                  case 'block_content__banner':

                    return (
                      <>
                        <>
                          <PageBanner
                            visuel={getImage(
                              imagesArray,
                              block?.relationships?.field_image?.drupal_internal__mid
                            )}
                            scroll={true}
                            key={i}
                            reverse={false}
                          >
                            <div className="wrapper_page">
                              <Breadcrumb
                                page_banner={true}
                                data={{
                                  parentPage: {
                                    title: breadCrumb?.parent?.name,
                                    url: breadCrumb?.parent?.link,
                                  },
                                  currentPage: {
                                    title: breadCrumb?.current?.name,
                                    url: breadCrumb?.current?.link,
                                  },
                                  locale: pageData?.langcode,
                                }}
                              />
                              <TitlePage
                                color="color_white"
                                title={block?.field_main_title}
                                description={block?.field_subtitle?.processed}
                              />
                              {block?.field_link?.url && <div className='button_container'>
                                <Button
                                  label={block?.field_link?.title}
                                  link={block?.field_link?.url}
                                  target='_self'
                                />
                              </div>}

                            </div>
                          </PageBanner>
                        </>
                      </>
                    );
                }
              })
            }

            <div className="background_white">

              {
                pageData?.relationships?.field_blocs?.map((block, i) => {
                  switch (block.__typename) {
                    case 'block_content__filtres':
                      filterArr = [];
                      block?.relationships?.field_filters?.forEach((filter) => {
                        filterArr.push(
                          {
                            id: filter?.drupal_internal__tid,
                            name: filter.name?.toUpperCase(),
                            img: getImage(
                              imagesArray,
                              filter?.relationships?.field_image?.drupal_internal__mid
                            ).uri?.url
                          }
                        );
                      });

                      return (
                        <div key={i}>
                          <BasCarbonCheckBox
                            checkTxt='BAS CARBONE'
                            checkVal={checkVal}
                            isChecked={filterCheckBox != null ? filterCheckBox : false}
                            onInfoClick={setOpenPopinCarbon}
                          />
                          <FilterTag
                            tags={InnerFilter}
                            onFilterChange={onFilterChange}
                            currentTag={filter_tag}
                          />
                          <PopinLowCarbon openProps={openPopinCarbon} onCloseClick={setOpenPopinCarbon} />
                        </div>
                      );
                    case 'block_content__slider':
                      return (
                        block.field_theme_bullets ? <SliderBullet
                          sliderBloc={block}
                          imagesArray={imagesArray}
                          key={i}
                        />
                          :
                          <Slider
                            sliderBloc={block}
                            imagesArray={imagesArray}
                            key={i}
                            loadVideo={loadVideo}
                          />
                      );

                    case 'block_content__solutions':

                      return (
                        <div key={i} >
                          <CardSection imagesArray={imagesArray} section={block} counter={i} listType='solutions' nosSolution={false} />
                        </div>
                      );

                    case 'block_content__nos_solutions':

                      return (
                        <div className='wrapperTop' key={i}>
                          {
                            block?.relationships?.field_solutions?.map((section, i) => (
                              <CardSection key={i} imagesArray={imagesArray} section={section} counter={i} listType={block?.field_list_type} nosSolution={true} chkBox={filterCheckBox} changed={changed} />
                            ))
                          }
                        </div>
                      );
                    case 'block_content__nos_projets':
                      let singleCardDetails = {};
                      let cardsArr = [];
                      block?.relationships?.field_projets?.forEach(cardDetails => {

                        singleCardDetails = {};
                        singleCardDetails.title = cardDetails?.title;
                        singleCardDetails.title_formatted = cardDetails?.field_title_formatted?.processed;
                        singleCardDetails.subTile = cardDetails?.field_subtitle;
                        singleCardDetails.paragraph = cardDetails?.field_description?.processed;
                        singleCardDetails.btn = cardDetails?.field_cta;
                        singleCardDetails.link = `/${cardDetails?.path?.langcode}${cardDetails?.path?.alias}`;
                        singleCardDetails.type = cardDetails?.field_bas_carbone;
                        singleCardDetails.img = getImage(imagesArray, cardDetails.relationships?.field_image?.drupal_internal__mid)?.image_style_uri?.gatsby_medium;
                        cardsArr.push(singleCardDetails);
                      })

                      return (
                        <section className='section_content product_page_component' key={i}>
                          <div className="wrapper_page">
                            <ProjectCards cards={cardsArr} bgArrow={false} chkBox={false} sec='' />
                          </div>
                        </section>
                      );

                    case 'block_content__nos_engagements':
                      return (
                        <NosEngagement
                          key={i}
                          NosTitle={block?.field_main_title}
                          NosText={block?.field_subtitle?.processed}
                          NosImg={getImage(imagesArray, block?.relationships?.field_image?.drupal_internal__mid)?.image_style_uri?.gatsby_large}
                          NosBg=''
                          NosLists={block?.body} />
                      );

                    case 'block_content__texte_avec_image':
                      return (
                        <section className='qui_sommes_nous section_content' key={i}>
                          <ShapeBackground
                            top={block?.field_display_background && 'right'}
                            color={block?.field_display_background ? 'light_blue'
                              : 'white'
                            }
                            bottomInfinite={block?.field_display_background}
                          >
                            <div className="wrapper_page_xs flex_reverse_mobile">
                              <TextVisuelTwoCol
                                title={block?.field_main_title}
                                colorTitle='color_yellow'
                                visuel={getImage(
                                  imagesArray,
                                  block?.relationships?.field_image?.drupal_internal__mid
                                )}
                                extremeLeft={
                                  block?.field_image_right ? false : true
                                }
                                extremeRight={
                                  block?.field_image_right ? true : false
                                }
                                orderInverse={
                                  block?.field_image_right ? true : false
                                }
                                alignTop={false}
                                text={block?.field_subtitle?.processed}
                                colorDescription="color_black"
                                titleHash={block?.field_title_arrow}
                              />
                            </div>

                          </ShapeBackground>
                        </section>
                      );
                    // break;
                  }
                })
              }
              {pageData?.field_description?.processed && (
                <>
                  <div className="wrapper_page">
                    <Breadcrumb
                      page_banner={false}
                      data={{
                        currentPage: { title: pageData?.title, url: breadCrumb?.current?.link },
                        locale: pageData?.langcode,
                      }}
                    />
                    <TitlePage
                      color="color_dark_bleu"
                      title={pageData?.title}
                      description=""
                    />
                  </div>
                  <section className="section_content page_footer">
                    <div
                      className="wrapper_page_xs"
                      dangerouslySetInnerHTML={{ __html: pageData?.field_description?.processed }}
                    ></div>
                  </section>
                </>

              )}
            </div>
          </div>
          {pageData?.field_display_contact && <SubFooter title={intl.formatMessage({ id: 'sticky.contactUs' })} linkText={intl.formatMessage({ id: 'sticky.fillContact' })} link={allMenu.filter((i) => i.node?.menu_name === 'footer-nous-contacter')[0]?.node?.link?.url} />}
        </>

      </Layout>
    </>
  );
};

export const query = graphql`
  query defaultTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    pageData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
          title
          field_description {
            processed
          }
          field_metatag{
            title
            description
          }
          metatag {
            attributes {
              content
              name
            }
          }
          field_display_contact
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__banner {
                  field_subtitle {
                    processed
                  }
                  field_link {
                    title
                    url
                  }
                  field_main_title
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__filtres {
                  relationships {
                    field_filters {
                      name
                      drupal_internal__tid
                      relationships {
                        field_image {
                          drupal_internal__mid
                        }
                      }
                    }
                  }
                }
                ... on block_content__slider {
                  field_theme_bullets
                  field_display_solution_name
                  field_subtitle {
                    processed
                  }
                  relationships {
                    field_title {
                      name
                    }
                    field_slides {
                      field_title {
                        processed
                      }
                      field_link {
                        title
                        url
                      }
                      relationships {
                        field_image {
                          drupal_internal__mid
                        }
                      }
                      langcode
                    }
                  }
                }
                ... on block_content__solutions {
                  field_subtitle {
                    processed
                  }
                  relationships {
                    field_title {
                      name
                    }
                    field_solution {
                      field_subtitle
                      field_cta
                      field_description {
                        processed
                      }
                      title
                      field_title_formatted { 
                        processed
                      }
                      field_lien{
                        uri															
                        url
                      }
                      path {
                        langcode
                        alias
                      }
                      relationships {
                        field_image {
                          drupal_internal__mid
                        }
                      }
                    }
                  }
                  field_display_background
                }
                ... on block_content__nos_solutions {
                  field_list_type
                  relationships {
                    field_solutions {
                      field_title {
                        processed
                      }
                      relationships {
                        field_category {
                          drupal_internal__tid
                        }
                        block_content__nos_solutions {
                          relationships {
                            field_solutions {
                              relationships {
                                field_contenu {
                                  __typename
                                  field_cta
                                  ... on Node {
                                    ... on node__solutions {
                                      field_description {
                                        processed
                                      }
                                      title
                                      field_title_formatted { 
                                        processed
                                      }
                                      field_subtitle
                                      field_bas_carbone
                                      path {
                                        alias
                                        langcode
                                      }
                                      field_lien{
					                              uri															
                                        url
                                      }
                                      relationships {
                                        field_image {
                                          drupal_internal__mid
                                        }
                                      }
                                    }
                                    ... on node__projets {
                                      field_description {
                                        processed
                                      }
                                      title
                                      path {
                                        alias
                                        langcode
                                      }
                                      relationships {
                                        field_image {
                                          drupal_internal__mid
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }   
                ... on block_content__nos_projets {
                  relationships {
                    field_projets {
                      __typename
                      ... on Node {
                        ... on node__projets {
                          field_description {
                            processed
                          }
                          field_cta
                          title
                          path {
                            alias
                            langcode
                          }
                          relationships {
                            field_image {
                              drupal_internal__mid
                            }
                          }
                        }  
                        ... on node__page {
                          field_description {
                            processed
                          }
                          field_cta
                          title
                          path {
                            alias
                            langcode
                          }
                          relationships{
                            field_image{
                              drupal_internal__mid
                            }
                          }
                        }  
                      }
                    }
                  }
                }  
                ... on block_content__nos_engagements {
                  id
                  body {
                    processed
                  }
                  field_main_title
                  field_subtitle {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__texte_avec_image {
                  field_display_background
                  field_image_right
                  field_main_title
                  field_subtitle {
                    processed
                  }
                  field_title_arrow
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
              }
            }
          }
        }
      }
    }

    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }

  }
`;

export default DefaultPage;
