import React from 'react';

import './FiltersTag.scss';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
let classNames = require('classnames');

const FiltersTag = ({ tags, currentTag, customClass, onFilterChange }) => {
  const handleChange = (e, tag) => {
    e.preventDefault();
    onFilterChange(currentTag?.id === tag.id ? false : tag);
  };

  const settings = {
    slidesToShow: 3,
    slide: 'li',
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 9999,
        settings: 'unslick'
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
        }
      }
      ,
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
        }
      }
    ]
  };

  return (
    <div className={classNames('filters_tag_component', customClass || null)}>
      <ul>
        <Slider {...settings}>
          {
            tags.map((tag, i) => (

              <li className='slick_wrap' key={i}>
                <a
                  href="#"
                  className={classNames({ 'active': currentTag?.id === tag.id })}
                  onClick={(event) => handleChange(event, tag)}
                  aria-expanded={currentTag?.id === tag.id ? 'true' : 'false'}
                >
                  <span className='img-wrap'>
                    <img src={tag.img} />
                  </span>
                  <span className='txt-wrap'>
                    {tag.name}
                  </span>
                </a>
              </li>

            ))
          }
        </Slider>
      </ul>
    </div>
  );
};

export default FiltersTag;
