import * as React from 'react';

import ShapeBackground from '../../Molecules/ShapeBackground/ShapeBackground';
import TitleBlock from '../../Molecules/TitleBlock/TitleBlock';
import TitleSection from '../../Molecules/TitleSection/TitleSection';

import './NosEngagement.scss';

const NosEngagement = ({ NosTitle, NosText, NosImg, NosLists, NosTitleType, NosBg }) => {
  return (
    <section className={'NosEngement section_content ' + NosBg}>
      <ShapeBackground
        top={'left'}
        color={'dark_bleu'}
      >
        <div className="wrapper_page_xs">
          <TitleSection
            title={NosTitle}
            subtitle={''}
            type={NosTitleType}
            h2color="white"
            h4color="yellow"
          />
          <div className="flex_content">

            <div className="text">
              <div className='txt-wrapper' dangerouslySetInnerHTML={{ __html: NosText }} />
            </div>
            <div className="img-wrapper">
              <div className='img'>
                <img src={NosImg} />
              </div>
            </div>
          </div>
          <div className="bullet-wrapper">
            {NosLists.map(
              list => {
                return (
                  <div className='list-wrap' dangerouslySetInnerHTML={{ __html: list.processed }}>

                  </div>
                );
              }
            )}
          </div>
          <div className="mobile-img-wrapper">
            <div className='img'>
              <img src={NosImg} />
            </div>
          </div>

        </div>
      </ShapeBackground>
    </section>
  );
};

export default NosEngagement;
