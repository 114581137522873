import * as React from 'react';

import PropTypes from 'prop-types';

import useMedias from '../../../hooks/useMedias';
import { Button } from '../../../Molecules/Button/Button';
import TitleBlock from '../../../Molecules/TitleBlock/TitleBlock';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';

import './TextVisuelTwoCol.scss';
import { MasqueBannerPageReverse, MasqueBannerPageReverseMobile, NewVerticalYellowLineShape, NewVerticalYellowLineShapeBold, VerticalYellowLineShape } from '../../../Atoms/Icons/Icons';
import { useEffect } from 'react';
import { useState } from 'react';

let classNames = require('classnames');

const TextVisuelTwoCol = ({
  extremeLeft,
  extremeRight,
  visuel,
  alt,
  colorTitle,
  colorDescription,
  title,
  text,
  textList,
  titleBackground,
  titleHash,
  alignTop,
  orderInverse,
  cta,
  textListTwoCol
}) => {
  const { processUrl } = useMedias();
  const { ctaText, ctaLink, ctaTarget } = cta || {};

  return (
    <div className={classNames(textListTwoCol ? 'two_col_with_banner' : '')}>
      {textListTwoCol
        ? <div className="visuel"><MasqueBannerPageReverse className={classNames('shape_yellow_banner_bottom', 'only_desktop', 'reverse')} /><MasqueBannerPageReverseMobile className={classNames('shape_yellow_banner_bottom', 'only_mobile')} /></div>
        : null
      }
      <div className={classNames(textListTwoCol ? 'container_title_txt' : '')}>
        <>
          {titleHash &&
            <div className={classNames('title_hash title_section', textListTwoCol ? 'type_line_banner_reverse' : 'type_line')}>
              <div className={classNames('line', 'icon')}>
                <NewVerticalYellowLineShape customCLass="only_desktop" />
                <NewVerticalYellowLineShapeBold customCLass="only_mobile_tablet" />
              </div>
              <div className='title_text'>
                <h2>{title}</h2>
              </div>
            </div>
          }
        </>
        <div className={classNames('text_visuel_two_col', textListTwoCol ? 'text_visuel_two_col_no_visuel' : '')}>
          <div
            className={classNames({
              cross_wrapper_extreme_right: extremeRight,
              cross_wrapper_extreme_left: extremeLeft,
            })}
          >
            <div
              className={classNames(
                'text_visuel',
                { order_inverse: orderInverse },
                { align_top: textList || alignTop }
              )}
            >
              {visuel && (
                <div className={classNames('visuel')}>
                  {visuel && (
                    <img
                      src={processUrl(visuel?.image_style_uri?.gatsby_large)}
                      alt={alt}
                      style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                    />
                  )}
                </div>
              )}
              <div className={classNames('text_container')}>
                <div
                  className={classNames('text_center', {
                    no_title: !title && !titleHash,
                  })}
                >
                  {title && !titleHash && (
                    < TitleSection
                      title={title}
                      subtitle={''}
                      h3color={colorTitle}
                    />
                  )}
                  {text && (
                    <div
                      className={colorDescription ? colorDescription : ''}
                      dangerouslySetInnerHTML={{ __html: text }}
                    ></div>
                  )}
                  {textList && (
                    <ul className={classNames(
                      colorDescription ? colorDescription : '',
                      textListTwoCol ? 'two_col' : ''
                    )}>
                      {textList.map((textItem, index) => (
                        <li key={index}>{textItem.text}</li>
                      ))}
                    </ul>
                  )}
                  {ctaText && ctaLink && (
                    <Button
                      label={ctaText}
                      link={ctaLink}
                      target={ctaTarget ? '_blank' : '_self'}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TextVisuelTwoCol.defaultProps = {
  text: '',
  alt: '',
  text_color: 'color_dark_bleu',
  titleBackground: false,
};

TextVisuelTwoCol.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  extremeRight: PropTypes.bool,
  extremeLeft: PropTypes.bool,
  cta: PropTypes.object,
};

export default TextVisuelTwoCol;
