import React, { useState, useEffect } from 'react';

import './BasCarbonCheckBox.scss';
import img from '../../assets/images/bas-carbone.svg';
import imgHover from '../../assets/images/bas-carbon-hover.svg';
import { InfoIcon } from '../../Atoms/Icons/Icons';

import { FormattedMessage } from '../../../plugins/publicis-gatsby-plugin-i18n';
function BasCarbonCheckBox({ checkVal, isChecked, onInfoClick }) {
  const [checked, setChecked] = useState(isChecked);
  let checkboxBasCarbon;

  useEffect(() => {
    checkVal(checked);
  }, [checked]);

  useEffect(() => {
    checkboxBasCarbon = document.getElementById('basCarbon');
    checkboxBasCarbon && (checkboxBasCarbon.checked = isChecked);
    setChecked(isChecked);
  }, [isChecked]);

  const openPopinBasCarbon = (e) =>{
    e.preventDefault();
    onInfoClick(true);
  }

  return (
    <div className="main-checkBox">
      <p><FormattedMessage id="common.filter_solution_low_carbon" /></p>
      <a href="#" className="info-icon" onClick={(e) => openPopinBasCarbon(e)}>
        <InfoIcon />
      </a>
      
      <div className="checkbox-wrapper">
        <label className="switch">
          <input id='basCarbon' type="checkbox" value={checked} onChange={(e) => setChecked((checked) => !checked)} />
          <span className="slider round"></span>
        </label>
      </div>
      <a className="img-wrapper" onClick={(e) => openPopinBasCarbon(e)}>
        <img src={img} alt="" />
        <img className="hover" src={imgHover} alt="" />
      </a>
    </div>
  );
}

export default BasCarbonCheckBox;
